
import { defineComponent, onMounted, reactive, ref } from 'vue'
import Summary from '@/components/subscriptions/add/Summary.vue'
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Company } from '@/store/modules/CompanyModule'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// interface NewAddressData {
//     companyName: string;
//     firstName: string;
//     lastName: string;
//     phoneNumber: string;
//     emailAddress: string;
//     accountType: string;
//     subscriptionType: string;
//     settings: { allowedToPublish: boolean, allowedToDeploy: boolean };
// }

interface NewCompany {
    label: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    countryCode: string;
    accountType: string | undefined;
    settings: {
        allowDeploy: boolean,
        allowPublish: boolean,
        allowTrial: boolean,
        trialStart?: string,
        trialEnd?: string
    }
}

export default defineComponent({
  name: 'add-company',
  components: {
    Summary,
    Datepicker
  },
  setup () {
    onMounted(() => {
      setCurrentPageBreadcrumbs('Add Company', ['Companies'])
    })
    const store = useStore()
    const router = useRouter()
    const formRef = ref<null | HTMLFormElement>(null)
    const newTargetModalRef = ref<null | HTMLElement>(null)
    const loading = ref<boolean>(false)

    const trialDays = ref(14) as any
    const trialDates = ref([new Date(), new Date().setDate((new Date()).getDate() + trialDays.value)]) as any
    const handleDaysChange = (currentNumber, oldNumber) => {
      if (typeof trialDates.value !== 'undefined' && trialDates.value !== null && typeof (trialDates.value[1]) !== 'undefined') {
        const date = new Date(trialDates.value[1])
        if (currentNumber > oldNumber) {
          date.setDate(date.getDate() + (currentNumber - oldNumber))
        } else {
          date.setDate(date.getDate() - (oldNumber - currentNumber))
        }
        trialDates.value[1] = date.toUTCString()
      }
    }
    const targetData = reactive<Company>({
      companyUuid: '',
      label: '',
      country: { name: '', code: '' },
      account: { accountUuid: '', firstName: '', lastName: '', email: '', fullName: '', createdAt: '', updatedAt: '', phone: '', roleId: 1 },
      settings: {
        allowedToPublish: false,
        allowedToDeploy: true,
        allowedTrial: false,
        trialRange: trialDates.value[1] + '-' + trialDates.value[2]
      },
      createdAt: '',
      updatedAt: ''
    })
    const onRangeChanges = (modelData) => {
      targetData.settings.trialRange = modelData
    }

    const rules = ref({
      label: [
        {
          required: true,
          message: 'Please input Company name',
          trigger: 'change'
        }
      ],
      'account.firstName': [
        {
          required: true,
          message: 'Please input Master account first name',
          trigger: 'change'
        }
      ],
      'account.lastName': [
        {
          required: true,
          message: 'Please input Master account last name',
          trigger: 'change'
        }
      ],
      'account.phone': [
        {
          required: true,
          message: 'Please input the Phone number',
          trigger: 'change'
        }
      ],
      'account.email': [
        {
          required: true,
          message: 'Please input the Email address',
          trigger: 'change'
        }
      ],
      'account.type': [
        {
          required: true,
          message: 'Please select the account type',
          trigger: 'change'
        }
      ],
      'settings.trialRange': [
        {
          required: true,
          message: 'Please select the date range',
          trigger: 'change'
        }
      ],
      'country.code': [
        {
          required: true,
          message: 'Please select the country',
          trigger: 'change'
        }
      ]
    })

    const submit = async (values) => {
      if (!formRef.value) {
        return
      }
      let formValidation = false
      formRef.value.validate((valid) => {
        if (!valid) {
          formValidation = false
          return false
        }
        loading.value = true
        formValidation = true
      })
      if (!formValidation) {
        await store.commit(Mutations.SET_COMPANY_ERRORS, {})

        Swal.fire({
          text: 'Sorry, looks like there are some errors detected, please try again.',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Ok, got it!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        loading.value = false

        return
      }

      const payload = reactive({
        label: targetData.label,
        firstName: targetData.account.firstName,
        lastName: targetData.account.lastName,
        email: targetData.account.email,
        phone: targetData.account.phone,
        countryCode: targetData.country.code,
        accountType: targetData.account.type,
        settings: {
          allowDeploy: targetData.settings.allowedToDeploy,
          allowPublish: targetData.settings.allowedToPublish,
          allowTrial: targetData.settings.allowedTrial
        }
      }) as unknown as NewCompany

      if (targetData.settings.allowedTrial) {
        payload.settings.trialStart = trialDates.value[0]
        payload.settings.trialEnd = (new Date(trialDates.value[1])).toISOString()
      }

      store.dispatch(Actions.API_CREATE_NEW_COMPANY, payload)
        .then(() => {
          Swal.fire({
            text: 'You have successfully created the company ' + payload.label,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-primary'
            }
          }).then(function () {
            // Go to page after successfully login
            loading.value = false

            router.push({ name: 'companies' })
          })
        }).catch(() => {
          loading.value = false
        })
    }

    return {
      targetData,
      trialDates,
      trialDays,
      handleDaysChange,
      submit,
      onRangeChanges,
      loading,
      formRef,
      rules,
      newTargetModalRef
    }
  }
})
